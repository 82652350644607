import EmailsLanding from '../components/EmailsLanding'
import { Helmet } from "react-helmet";
import React from 'react'
import { usePageAnalyitics } from '../helpers/hooks/usePageAnalytics';

const NotFound = () => {
  usePageAnalyitics()
  return (
    <>
     <Helmet>
        <meta charSet="utf-8" />
        <title> 404  | AURA Financial</title>
      </Helmet>
      <EmailsLanding 
        title='404'
        text='Upsss... no pudimos encontrar la pagina'
        notFound={true}
        timeReturnHome={10000}
    />
    
    </>
  
  )
}

export default NotFound