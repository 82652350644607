import React from 'react'
import graphicImage from '../assets/images/escala.png'
import securityImage from '../assets/images/candado.png'
import heartImage from '../assets/images/corazon.png'
import '../assets/css/components/intereses.css'
const Intereses = () => {
    return (
        <div className="intereses">
            <div className="intereses--titulos">
                <div className="row">
                    <div className="col-lg-8 offset-lg-2-3">
                        <div className="section-heading  wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.5s">
                            <p>INTERESES ALINEADOS</p>
                            <h2>Pensamos en lo que es importante para ti.</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="intereses__cards">
                <div className="intereses__cards--box">
                    <h3>Seguridad</h3>
                    <div className="intereses__cards--images">
                        <img src={graphicImage} alt='graphic '/>
                    </div>
                    <p>Sin falsas promesas. Estrategias y productos con la máxima seguridad.</p>
                </div>
                <div className="intereses__cards--box">
                    <h3>Tus datos te pertenecen</h3>
                    <div className="intereses__cards--images">
                        <img src={securityImage} alt='security' />
                    </div>
                    <p>Con tus datos solo optimizamos nuestras recomendaciones.</p>
                </div>
                <div className="intereses__cards--box">
                    <h3>Promover conscientemente la sostenibilidad</h3>
                    <div className="intereses__cards--images">
                        <img src={heartImage} alt='heart ' />
                    </div>
                    <p>¿Impacto sostenible y mejorar tus ganancias? Te mostramos como.</p>
                </div>
            </div>
        </div>
    )
}

export default Intereses