import React from 'react'
import EmailsLanding from '../components/EmailsLanding'
import { Helmet } from "react-helmet";
import { usePageAnalyitics } from '../helpers/hooks/usePageAnalytics';


const ConfirmationEmail = () => {
  usePageAnalyitics()
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Confirmation | AURA Financial</title>
      </Helmet>
      <EmailsLanding
        title='¡Gracias por confirmar tu correo electrónico!'
        text='Muy pronto estarás recibiendo las últimas noticias y tips de salud financiera.'
        socialMedias={true}
      />
    </>
  )
}

export default ConfirmationEmail