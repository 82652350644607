import React from 'react'
import PropTypes from 'prop-types'
import cellPhone from '../assets/images/Group 18.png'
import '../assets/css/components/banner.css'

const Banner = ({ countryName ,modalCountry }) => {
  const validacionCountry = countryName === 'bo' ? 'Bolivia' : countryName === 'mx' ?  'México' : countryName === 'co' ? 'Colombia' :""
  
  return (
    <div className="main-banner wow fadeIn" id="top" data-wow-duration="1s" data-wow-delay="0.5s">
      <div className="container--inicio">
        <div className="row ">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-6 align-self-center padding" >
                <div className="left-content show-up header-text wow fadeInLeft" data-wow-duration="1s" data-wow-delay="1s">
                  <div className=" future__finance">
                    <div className="col-lg-12 ">
                      <p id='container__inicio--country'>En {validacionCountry}</p>
                      <h1 className="title--primary">Tu futuro financiero,<br />en un solo paso.</h1>
                      <p>
                          Porque sabemos que en compañía todo es mejor,<strong> AURA App </strong>
                          estará a tu lado durante toda tu vida financiera. <br />
                          <br />
                          Ahorra, invierte e incluso solicita un crédito sin
                          esfuerzo y de forma automática.
                        </p>
                    </div>

                    <div id="button--intro1">
                      <div className="banner__button" >
                         <span className='banner__button--free'>¡ Es Gratis !</span>
                         <button onClick={()=>{modalCountry(true)}} >Registrate</button>

                      </div>
                   
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="right-image wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
                  <img src={cellPhone} alt={'mockup de un celular simulando una app'} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
Banner.propTypes = {
  countryName: PropTypes.string,
}
Banner.defaultProps = {
  countryName: "",
}
export default Banner
