import React,{useEffect, useState} from 'react'

import noticeImage from '../assets/images/notice.png'

import powerFinacial from '../assets/images/Group 35 (1).png'
import objetivesFinancial from '../assets/images/Group 37.png'

import imageNotice1 from '../assets/images/noticiasmanos.png'
import imageNotice2 from '../assets/images/Group 40.png'
import imageNotice3 from '../assets/images/Group 41.png'



import bankNot from '../assets/images/Group_44-removebg.png'
import Footer from './Footer'
const Information = ({handleModal}) => {
 const urlActual = window.location
  const [countryName, setcountryName] = useState('');
  useEffect(() => {
    if (urlActual.href.includes('/bo')) {  
      setcountryName('bol')
    }
    else{
      setcountryName('mx')
    }
  }, []);
    window.scrollTo(0, 0);
  return (
    <div className="finance information">
           <div className="container--titulos">
            <div className="row">
              <div className="col-lg-8 offset-lg-2-3">
                <div className="section-heading  wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.5s">
                  <p>LA APLICACIÓN FINANCIERA HOLÍSTICA</p>
                  <h3>Siéntete bien con tu futuro financiero.</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="financial__container">
            <div className="financial__title">
              <h3>Tu vida financiera
                perfectamente equilibrada</h3>
            </div>
            <div className="financial__image">
            </div>
          </div>
          <div className="financial__section2">
            <div className="financial__morado">
              <h3>Tareas concretas para que puedas avanzar</h3>
              <img src={noticeImage}  />
            </div>
            <div className="financial__white">
              <h3>Descubre tu potencial financiero</h3>
              <img src={powerFinacial}  />
            </div>
          </div>
          <div className="financial__ahorro">
            <div className="financial__ahorro--image">
              <img src={objetivesFinancial}  />
            </div>
            <div className="financial__ahorro--texto">
              <h3>Cajitas para cada objetivo. Pero con ganancias.</h3>
            </div>
          </div>

          <div className="financial__notices">
            <div className="financial__notices--box">
              <div className="notices__images">
                <img src={imageNotice1}  />
              </div>
              <p>Tome decisiones con el conocimiento de expertos financieros</p>
            </div>
            <div className="financial__notices--box">
              <div className="notices__images">
                <img src={imageNotice2}  />
              </div>
              <p>Información y consejos individuales para toda su vida financiera</p>
            </div>
            <div className="financial__notices--box">
              <div className="notices__images">
                <img src={imageNotice3}  />
              </div>
              <p>Respuestas personales a todas sus preguntas financieras</p>
            </div>
          </div>
          {countryName === 'bol' ? '' : 
           <div className="financial__app">
           <div className="financial__app--texto">
             <h2>Un Banco?
               No algo mejor
             </h2>
             <p>
               Aura es la aplicación holística para su vida financiera, con asesoramiento personal e inversiones
               automatizadas.
             </p>
           </div>
           <div className="financial__app--image">
             <img src={bankNot} />
           </div>
         </div>
        }
          <Footer handleModal={handleModal}/>
    </div>
  )
}

export default Information