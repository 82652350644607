import React from 'react'
import { useNavigate } from 'react-router'

const Button = ({ contenido = 'Registrate', clases, route, click}) => {
    let navigate = useNavigate()
    return (
        <div  className={`button--component ${clases === 'left' ? clases : ""}`}>
                <button onClick={click} className={`${clases ? clases : ""}`}>{contenido}</button>
        </div>
    )
}

export default Button