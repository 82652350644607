import React from 'react'
import misionImage from '../assets/images/coinjar.jpg'
import '../assets/css/components/mision.css'

const Mision = () => {
    return (

        <div className="mision" id='mision' >
            <div className="mision__container" >
                <div className="mision__image">
                    <img src={misionImage} alt={'planta'} />
                </div>
                <div className="mision__text">
                    <p  className="mision--p">NUESTRA MISIÓN</p>
                    <h2 className="mision--info">Que todos puedan dar forma a su futuro financiero, con el conocimiento necesario e independiente de sus activos financieros.</h2>
                </div>
            </div>
        </div>
    )
}

export default Mision