import React, { useEffect } from 'react'
import '../assets/css/doopler.css'
import ReactGA from "react-ga4";

const Dopper = ({ modalHandle ,activeModal}) => {
 
    useEffect(() => {
        ReactGA.send({ page:"Doppler | AURA Financial" });

        const script = document.createElement('script');
        script.src = 'https://cdn.fromdoppler.com/formgenerator/latest/vendor.js?23194400';
        document.body.appendChild(script);

        const link = document.createElement('link')
        link.href = 'https://cdn.fromdoppler.com/formgenerator/latest/styles.css?23194400'
    }, [activeModal]);
    return (<>
        <main className='dopler'>
            <div className="dopler__out" onClick={() => { modalHandle(false) }}>
            </div>
            <div className='dopler__container'>
                <div className="dopler--button">
                    <button onClick={() => { modalHandle(false) }}>x</button>
                </div>
                <link rel="stylesheet" href="https://cdn.fromdoppler.com/formgenerator/latest/styles.css?23194400" />
                <div data-dp-form="IXiZ4q%2bvqZDNa3u%2b42y5YA%3d%3d"></div>
            </div>
        </main>
    </>
    )
}

export default Dopper