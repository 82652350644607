import React, {  useEffect } from 'react'
import Header from './Header'
import { useNavigate } from 'react-router'
import PropTypes from 'prop-types'

import { eventAnalytics } from '../helpers/hooks/usePageAnalytics';

import planeImg from '../assets/images/plane-email.png'
import notfoundImg from '../assets/images/404bg.png'
import instagramIcon from '../assets/images/social media.svg'
import facebookIcon from '../assets/images/facebook.svg'
import '../assets/css/emailslanding.css'



const EmailsLanding = ({ title, text, notFound, socialMedias, timeReturnHome }) => {
    let navigate = useNavigate()
    const socialAnalytics = (socialMedia) => {
        eventAnalytics({
            name:socialMedia,
            action: `${socialMedia} button`,
            category: "redes sociales"
        })
    }
    useEffect(() => {
        {timeReturnHome &&
                setTimeout(() => {
                    navigate(validationCountry)
                }, timeReturnHome)
        }
    }, []);
    const countryName = (window.location.pathname);
    const validationCountry = countryName.includes("bo")
    ? "/bo"
    : countryName.includes("mx")
    ? "/mx"
    : countryName.includes("co")
    ? "/co"
    : "/";
    return (
        <>
            <Header />
            <div className='emailverification' >
                <main className="emailverification__container">
                    <div className="emailverification__container--texto">
                        <h2>{title}</h2>
                        <p className='emailverification--p'>{text}</p>
                        <button
                            onClick={() => { navigate(validationCountry) }}
                            className='email__container--button'
                        >Regresar al Inicio</button>
                        {socialMedias ?
                            <div className="email__socials">
                                <p className='email__socials--follow'>Siguenos en</p>
                                <div className="email__socials--imgs">
                                    <a
                                        onClick={() => { socialAnalytics('Instagram') }}
                                        href="https://www.instagram.com/aura.financial/" target="_blank" rel="noopener noreferrer">
                                        <img src={instagramIcon} alt="" />
                                    </a>
                                    <a
                                        onClick={() => { socialAnalytics('Facebook') }}
                                        href="https://www.facebook.com/aura.Bolivia01" target="_blank" rel="noopener noreferrer">
                                        <img src={facebookIcon} alt="" />
                                    </a>
                                </div>
                            </div>
                            : ""

                        }

                    </div>
                    {!notFound ?
                        <div className="emailverification__container--img">
                            <img src={planeImg} alt="imagen de papel" />
                        </div>
                        :
                        <div className="emailverification__container--404">
                            <img src={notfoundImg} alt="404 image" />
                        </div>

                    }
                </main>

            </div>
        </>

    )
}
EmailsLanding.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string,
    notFound: PropTypes.bool,
    socialMedias: PropTypes.bool,
    timeReturnHome: PropTypes.number

}
EmailsLanding.defaultProps = {
    notFound: false,
    socialMedias: false,
    timeReturnHome: NaN
}

export default EmailsLanding