import React, { useEffect, useState } from 'react'

import Auralogo from '../../assets/images/logo_horizontal_solo.png'
import WOW from 'wowjs';

const HeaderMyPyme = () => {
    const [y, setY] = useState(0);

    const handleNavigation = (e) => {
        const window = e.currentTarget;
        setY(window.scrollY);
    };
    useEffect(() => {
        new WOW.WOW({
            live: false
          }).init();
    }, []);
    
    useEffect(() => {
        setY(window.scrollY);
        window.addEventListener("scroll", (e) => handleNavigation(e));
        return () => {
            window.removeEventListener('scroll', (e) => handleNavigation(e))
        };
    }, [y]);

    return (
        <header className={y < 70 ? `headermypyme header-sticky wow slideInDown` : `headermypyme headermypyme--active header-sticky wow slideInDown animated background-header`} data-wow-duration="0.75s" data-wow-delay="0s">
            <div
            className='headermypyme__logo'
            >
            <a href="/">
                <img src={Auralogo} alt="" className='headermypyme__logo--image' />
            </a>
            </div>
        </header>
    )
}

export default HeaderMyPyme
