import React from 'react'
import RotateScreen from '../components/RotateScreen'
import Helmet from 'react-helmet'
import { usePageAnalyitics } from '../helpers/hooks/usePageAnalytics'
const RegisterQR = () => {
  usePageAnalyitics()
  return (
    <>
      <Helmet>
        <title>Register | AURA Finance</title>
      </Helmet>
      <iframe title='goRegister' id="typeform-full" width="100%" height="100%" frameBorder="0" src="https://go.moyoai.com/Au22BKe5" allow="geolocation; camera">
      </iframe>
      <RotateScreen />

    </>
  )
}

export default RegisterQR