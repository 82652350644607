import React, { useEffect, useState } from "react";
import Information from "../components/Information";

import Header from "../components/Header";
import { Helmet } from "react-helmet";
import { usePageAnalyitics } from "../helpers/hooks/usePageAnalytics";
import { useLocation } from "react-router";
import Dopper from "../components/Doppler";

const Appholistica = () => {
  const [country, setCountry] = useState("");
  const [modal, setModal] = useState(false);
  const [headerActive, setHeaderActive] = useState(true);
  const handleModal = (active) => {
    const container = document.querySelector('.dopler')
    if (active) {
      document.documentElement.style.overflow = 'hidden';
      document.body.scroll = "no"; // IE
      container.style.display = 'block'
      setHeaderActive(!headerActive)
      setModal(true)
    } else if (!active) {
      document.documentElement.style.overflow = 'auto';
      document.body.scroll = "yes"; // IE
      container.style.display = 'none'
      setHeaderActive(!headerActive)
      setModal(false)

    }
  }
  let location = useLocation();
  usePageAnalyitics();
  const validateCountry = location.pathname.includes("mx")
    ? "mx"
    : location.pathname.includes("co")
    ? "co"
    : location.pathname.includes("bo")
    ? "bo"
    : "mx";

  const validacionCountry =
    validateCountry === "bo"
      ? "Bolivia"
      : validateCountry === "mx"
      ? "México"
      : validateCountry === "co"
      ? "Colombia"
      : "";

  useEffect(() => {
    setCountry(validateCountry);
  }, [validateCountry]);
  return (
    <>
      {/* asda */}
      <Helmet>
        <meta charSet="utf-8" />
        <title>Como lo Hacemos | AURA Financial {validacionCountry}</title>
      </Helmet>

      <Dopper activeModal={modal} modalHandle={handleModal} />
      {headerActive && <Header countryName={country} />}
      
      <Information handleModal={handleModal} />
    </>
  );
};
export default Appholistica;
