import Button from '../Button'
import React from 'react'

const TwoSection = ({ title, text, number, buttonText , imageUrl , openModal }) => {
    return (
        < div className="landing__02" >
                <h4>{number}</h4>
            <div className="landing__02--container">
                <div className="circle--2">
                    <img src={imageUrl} alt="" />
                </div>
                <div className="landing__02--text">
                    <h2 >{title}</h2>
                    <p>{text}</p>
                    <button onClick={() => {
                      openModal(true)
                    }} className='landing--button'>{buttonText} </button>
                </div>
            </div>
        </div >
    )
}

export default TwoSection