import { Link } from 'react-router-dom'
import flagBolivia from '../../assets/images/Bandera_de_Bolivia_(Estado) (1) 1.svg'
const FirstSection = ({title , text , textButton, imageUrl, openModal}) => {
    return (
        <div className='landing__one--container'>
        <section className='landing__one--section'>
            <div className="landing__one--text">
                <div className="landing__one--flag">
                    <h5>En Bolivia</h5>
                    <img src={flagBolivia} alt="" />
                </div>
                <h1>{title}</h1>
                <p>{text}</p>
                <div className="landing__one--button">
             
                        
                <button className='landing--button' onClick={()=>{openModal(true)}}>{textButton}</button>
                 
                    <span>- ¡ Es gratis !</span>
                </div>
            </div>
            <div className='landing__one--image'>
                <img src={imageUrl} alt="" />
            </div>

        </section>
        </div>
    )
}

export default FirstSection