import Button from '../../components/Button'
import React from 'react'

const SecondSection = ({ title, text, imageUrl }) => {
    return (
        <main className='section--two--container'>
            <section className='section--two'>
                <div className="landing__two--text">
                    <div className="landing__two--title">
                        <p>Lo que tu negocio necesita</p>
                        <h2>¿Por qué AURA?</h2>
                    </div>
                    <div className="landing__two--container">
                        <div className="landing__circle">
                            <img src={imageUrl} alt="" />
                        </div>

                        <div className="landing__opurtunites landing__opurtunites--circle">
                            <h2>{title}</h2>
                            <p>{text}</p>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}

export default SecondSection
// /bo/mypyme
// 16 px todos los textos
// h2 utlima seccion 
// como funciona? antes de las secciones
// bajar el header en mobile
// privacy
// margin top al button 
// app holistica hay que pegar imagen 
// mas aire entre las imagenes y el texto