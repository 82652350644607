import "./vendor/bootstrap/css/bootstrap.min.css";
import "./assets/css/animated.css";
import "./assets/css/owl.css";
import "./assets/css/templatemo-chain-app-dev.css";
import "./index.css";

import React, { useEffect, useState } from "react";

import AhorroInversion from "./components/AhorroInversion";
import Banner from "./components/Banner";
import ComoFunciona from "./components/ComoFunciona";
import Credito from "./components/Credito";
import Dopper from "./components/Doppler";
import Estrategias from "./components/Estrategias";
import Footer from "./components/Footer";
import Header from "./components/Header";
import { Helmet } from "react-helmet";
import Intereses from "./components/Interes";
import Mision from "./components/Mision";
import Personalidades from "./components/Personalidades";
import SelectCountryModal from "./components/SelectCountryModal";
import Servicios from "./components/Servicios";
import WOW from "wowjs";
import { usePageAnalyitics } from "./helpers/hooks/usePageAnalytics";
import ModalComing from "./components/ModalComing";

function App({ countryName }) {
  usePageAnalyitics();
  const validacionCountry =
    countryName === "bo"
      ? "Bolivia"
      : countryName === "mx"
      ? "México"
      : countryName === "co"
      ? "Colombia"
      : "";

  const [modal, setModal] = useState(false);
  const [modalCountry, setmodalCountry] = useState(false);
  const [headerActive, setHeaderActive] = useState(true);
  const [modalComing, setModalComing] = useState(false);
  const handleModal = (active) => {
    const container = document.querySelector(".dopler");
    if (active) {
      document.documentElement.style.overflow = "hidden";
      document.body.scroll = "no"; // IE
      container.style.display = "block";
      setHeaderActive(!headerActive);
      setModal(true);
    } else if (!active) {
      document.documentElement.style.overflow = "auto";
      document.body.scroll = "yes"; // IE
      container.style.display = "none";
      setHeaderActive(!headerActive);
      setModal(false);
    }
  };

  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);
  useEffect(() => {
    if ( modalComing) {
      document.documentElement.style.overflow = "hidden";
      document.body.scroll = "no"; // IE
    } else {
      document.body.scroll = "yes"; // IE
      document.documentElement.style.overflow = "auto";
    }
  }, [modalComing]);
  return (
    <div className="app">
      <Helmet>
        <meta charSet="utf-8" />
        <title>AURA Financial | Home {validacionCountry}</title>
      </Helmet>
      <Dopper activeModal={modal} modalHandle={handleModal} />
      <div>
        {headerActive && <Header countryName={countryName}></Header>}
        <div>
          {modalCountry && (
            <SelectCountryModal    modalCountry={setmodalCountry} />
          )}
          {modalComing && 
            <ModalComing closemodal={setModalComing} />
          }
          <Banner countryName={countryName}   modalCountry={
              validacionCountry !== "Bolivia" ? setModalComing : setmodalCountry
            } />
          <Mision />
          <ComoFunciona
            countryName={countryName}
              modalCountry={
              validacionCountry !== "Bolivia" ? setModalComing : setmodalCountry
            }
          />
          <Estrategias />
          <Personalidades />
          <AhorroInversion
            countryName={countryName}
              modalCountry={
              validacionCountry !== "Bolivia" ? setModalComing : setmodalCountry
            }
          />
          <Credito countryName={countryName}   modalCountry={
              validacionCountry !== "Bolivia" ? setModalComing : setmodalCountry
            } />
          <Intereses />
          <Servicios countryName={countryName}   modalCountry={
              validacionCountry !== "Bolivia" ? setModalComing : setmodalCountry
            } />
          <Footer countryName={countryName} handleModal={handleModal} />
        </div>
      </div>
    </div>
  );
}

export default App;
