import React from 'react'
import savingImage from '../assets/images/ahorro.png'
import Button from './Button'
import '../assets/css/components/ahorroInversion.css'
const AhorroInversion = ({ countryName, modalCountry }) => {
    const validacionCountry = countryName === 'bo' ? 'Bolivia' : countryName === 'mx' ? 'México' : countryName === 'co' ? 'Colombia' : ""

    return (
        <div className="AhorrroInversion">
            <div className="AhorrroInversion__texto">
                <p className="AhorroInversion__subline">AHORRO E INVERSIÓN</p>
                <h2>Haz más con tu dinero</h2>
                <p>Todo el mundo debería obtener más de su dinero, invertirlo para el futuro sin esfuerzo, con una estrategia a
                    largo plazo y al mínimo coste.<br /> <br /> Ponemos tu dinero en modo <em>"turbo"</em>.</p>
                <div className="AhorroInversion__button">

                        <Button clases={'left'} click={(() => {modalCountry(true)})} />


                </div>
            </div>
            <div className="AhorrroInversion__phone">
                <img src={savingImage} alt='mockup de nuestra app' />
            </div>
        </div>

    )
}

export default AhorroInversion