import React from 'react'
import logoAura from '../../assets/images/Aura_logo.png'
const FooterLanding = () => {
    return (
        <div className='footerlanding'>
            <div className="footerlanding__logoaura">
                <a href="/">
                    <img src={logoAura} alt="" />
                </a>
            </div>
            <div className='footer__text'>
                <h5 className="footerlanding__main">
                    Preguntas: info-aura@moyoAI.com
                </h5>
                <div className="foooterlanding__privacy">
                    <a href="/privacity" target={'_blank'}>
                        Privacy
                    </a>
                    <span>|</span> Legal
                </div>
            </div>
        </div>
    )
}

export default FooterLanding