import {useEffect} from 'react'
import { useNavigate } from 'react-router'
import flagColombia from '../assets/images/Flag_of_Colombia 1.svg'
import flagBolivia from '../assets/images/Bandera_de_Bolivia_(Estado) (1) 1.svg'
import flagMexico from '../assets/images/Flag_of_Mexico 1.svg'

import '../assets/css/components/selecyourcountry.css'
const SelectCountryModal = ({modalCountry}) => {
    let navigate = useNavigate()
    useEffect(() => {
        document.body.style.overflow = 'hidden'
        return () => {
            document.body.style.overflow = 'unset'

        };
    }, []);
  
    return (
        <div className='selectcountry'>
            <div className='selectcountry__offmodal' onClick={()=>{modalCountry(false)}} />
            <div className="selectcountry__modal">
                <div className='selectcountry__modal--close'>
                    <button onClick={()=>{modalCountry(false)}}>X</button>
                </div>
                <main className="selectcountry__modal--content">
                    <h2>Selecciona tu país</h2>
                    <div className='selectcountry__flags'>
                        <img src={flagBolivia} alt="" onClick={()=>{navigate('/bo/registro-BO')}}/>
                        <p >Bolivia</p>
                    </div>
                    <div className='selectcountry__flags '>
                        <p className='selectcountry__flags--coming'>Próximamente</p>
                        <img src={flagMexico} alt="" className='coming' />
                        <p>Mexico</p>
                    </div>
                    <div className='selectcountry__flags '>
                        <p className='selectcountry__flags--coming '>Próximamente</p>
                        <img src={flagColombia} alt="" className='coming' />
                        <p>Colombia</p>
                    </div>
                </main>
            </div>

        </div>
    )
}

export default SelectCountryModal