import Button from '../Button'
import React from 'react'

const OneSection = ({ number, title, text, buttonText, imageUrl, openModal }) => {
    return (
        <section className='section--third'>
            <h4 className='landing__01--h4'>{number}</h4>
            <div className="landing__01">
                <div className="landing__01--container">
                    <div className="landing__circle--numbers">
                        <img src={imageUrl} alt="" className='landing__circle--image' />
                    </div>
                    <div className="landing__opurtunites">
                        <h2 >{title}</h2>
                        <p>{text}</p>
                        <button onClick={() => {
                            openModal(true)
                        }} className='landing--button'>{buttonText} </button>

                    </div>
                </div>
            </div>

        </section>
    )
}

export default OneSection