import '../assets/css/components/comofunciona.css'

import PropTypes from 'prop-types'
import React from 'react'
import checkBulletImage from '../assets/images/checkbullet.svg'

const ComoFunciona = ({ countryName , modalCountry }) => {
    const validacionCountry = countryName === 'bo' ? 'bo' : countryName === 'mx' ? 'mx' : countryName === 'co' ? 'co' : ""

    return (
        <div id='comolohacemos' className="comoFunciona">
            <div className="comolohacemos__title wow fadeInDown animated mb">
                <h2 className="title--estrategies">La forma más rápida de lograr salud financiera de manera inteligente
                </h2>
            </div>
            <div className="comoFunciona__list">
                <div className="comoFunciona__ul">
                    <div className="comoFunciona__item">
                        <img src={checkBulletImage} alt={'check o palomita'} />
                        <p>Conoce tu perfil crediticio.</p>
                    </div>
                    <div className="comoFunciona__item">
                        <img src={checkBulletImage} alt={'check o palomita'} />
                        <p>Recibe tips de educación financiera.</p>
                    </div>
                    <div className="comoFunciona__item">
                        <img src={checkBulletImage} alt={'check o palomita'} />
                        <p>Arma tu expediente digital.</p>
                    </div>
                    <div className="comoFunciona__item">
                        <img src={checkBulletImage} alt={'check o palomita'} />
                        <p>Recibe recomendaciones claras sobre tasas de ahorro y productos financieros adecuados a tu perfil.</p>
                    </div>
                    <div className="comoFunciona__item">
                        <img src={checkBulletImage} alt={'check o palomita'} />
                        <p>Toma decisiones relevantes para tu futuro.</p>
                    </div>
                    <div className="comoFunciona__buttons">
                            <button onClick={() => { modalCountry(true) }} className="clients__button--list">Registrate</button>
                        

                        <a href={`/${validacionCountry}/howto`}>
                        <button className="clients__button--list" >
                            Conoce Más
                        </button>
                    </a>
                </div>
            </div>
        </div>
        </div >
    )
}
ComoFunciona.propTypes = {
    countryName: PropTypes.string,
}
ComoFunciona.defaultProps = {
    countryName: "",
}



export default ComoFunciona;