import React, { useState, useEffect } from 'react'
import { HashLink } from '@xzar90/react-router-hash-link';
import { useLocation } from 'react-router-dom';

import banderaMex from '../assets/images/Flag_of_Mexico.svg'
import banderaBol from '../assets/images/Bandera_de_Bolivia_(Estado) (1).svg'
import banderaCol from '../assets/images/Flag_of_Colombia.svg'

import Auralogo from '../assets/images/Aura_logo.png'

import '../assets/css/templatemo-chain-app-dev.css';
const Header = ({ countryName }) => {
  const location = useLocation()
  const [y, setY] = useState(0);
  const [activeDown, setActiveDown] = useState(false);
  const handleToogleHamburguer = () => {
    const nav = document.querySelector('.nav')
    if (nav.style.display === 'none') {
      nav.style.display = 'block'
    } else {
      nav.style.display = 'none'
    }
  }
  const scrollWithOffset = (el, offset) => {
    const elementPosition = el.offsetTop - offset;
    window.scroll({
      top: elementPosition,
    })
  }
  const handleNavigation = (e) => {
    const window = e.currentTarget;
    setY(window.scrollY);
  };
  const validationFlag = () => {
    if (countryName === 'bo') {
      return (
        <img onClick={() => { setActiveDown(!activeDown) }} src={banderaBol} alt={'flag-bolivia'} className="banderas__bolivia" />
      )
    }
    else if (countryName === 'mx') {
      return (
        <img src={banderaMex} onClick={() => { setActiveDown(!activeDown) }} alt={'flag-mexico'} className="banderas__mex" />
      )

    } else if (countryName === 'co') {
      return (
        <img src={banderaCol} onClick={() => { setActiveDown(!activeDown) }} alt={'flag-colombia'} className="banderas__bolivia" />
      )
    }
  }
  const validateRoute = location.pathname.includes('/co') ? "/co"
    : location.pathname.includes('/bo') ? '/bo' : '/mx'

  const validateWidth = window.screen.width > 767 ? false : true;
  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener("scroll", (e) => handleNavigation(e));
    return () => {
      window.removeEventListener('scroll', (e) => handleNavigation(e))
    };
  }, [y]);

  return (
    <header className={y < 120 ? `header-area header-sticky wow slideInDown` : `header-area header-sticky wow slideInDown animated background-header`} data-wow-duration="0.75s" data-wow-delay="0s">
      <div className="container">
        <div className="row header-nav">
          <div className="col-12">
            <nav className="main-nav">
              <HashLink to={`${validateRoute}#top`} className="logo">
                <img src={Auralogo} alt="" className='imagelogo' />
              </HashLink>


              <ul className="nav">
                <li onClick={handleToogleHamburguer} className="scroll-to-section">
                  <HashLink to={`${validateRoute}#top`}>INICIO</HashLink></li>


                <li onClick={handleToogleHamburguer} className="scroll-to-section">
                  <HashLink
                    scroll={el => scrollWithOffset(el, 86)}
                    to={`${validateRoute}#mision`}>FILOSOFIA</HashLink>
                </li>


                <li onClick={handleToogleHamburguer} className="scroll-to-section">
                  <HashLink
                    scroll={el => scrollWithOffset(el, 80)}
                    to={`${validateRoute}#comolohacemos`}>COMO FUNCIONA</HashLink>
                </li>
                <li onClick={handleToogleHamburguer}
                  className="scroll-to-section">
                  <HashLink
                    scroll={el => scrollWithOffset(el, 80)}
                    to={`${validateRoute}#newsletter`}>

                    BOLETIN
                  </HashLink>
                </li>
                <li onClick={handleToogleHamburguer} className="bandera__container">
                  <div className="banderas">
                    {!validateWidth ?
                      <>
                        {validationFlag()}
                        {activeDown ?
                          <>
                            <div className='banderas__closeModal' onClick={() => { setActiveDown(false) }} />
                            <div className="banderas__container">
                              {countryName !== 'mx' &&
                                <a href='/mx' >
                                  <img src={banderaMex} alt={'flag-mexico'} className="banderas__mex" />
                                </a>
                              }
                              {countryName !== 'bo' &&
                                <a href='/bo'>
                                  <img src={banderaBol} alt={'flag-bolivia'} className="banderas__bolivia" />
                                </a>
                              }
                              {countryName !== 'co' &&
                                <a href='/co'>
                                  <img src={banderaCol} alt={'flag-colombia'} className="banderas__bolivia" />
                                </a>
                              }

                            </div>
                          </>
                          : ""
                        }
                      </>

                      :
                      <>
                        <div className='banderas__closeModal' onClick={() => { setActiveDown(false) }} />
                        <div className="banderas">
                            <a href='/mx' >
                              <img src={banderaMex} alt={'flag-mexico'} className="banderas__mex" />
                            </a>
                            <a href='/bo'>
                              <img src={banderaBol} alt={'flag-bolivia'} className="banderas__bolivia" />
                            </a>
                            <a href='/co'>
                              <img src={banderaCol} alt={'flag-colombia'} className="banderas__bolivia" />
                            </a>

                        </div>
                      </>
                    }


                  </ div>
                </li>
              </ul>
              <a className="menu-trigger" onClick={() => { handleToogleHamburguer() }}>
                <span>Menu</span>
              </a>
            </nav>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
