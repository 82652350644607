import React from 'react'
import EmailsLanding from '../components/EmailsLanding'
import { Helmet } from "react-helmet";
import { usePageAnalyitics } from '../helpers/hooks/usePageAnalytics';

const ThankYouRegister = () => {

  usePageAnalyitics()
  
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Thank You-Register | AURA Financial</title>
      </Helmet>
      <EmailsLanding
        title='¡Gracias por registrarte!'
        text={`Estamos trabajando para buscar las mejores opciones. Muy pronto nos pondremos en contacto.`}
        
        socialMedias={true}
      />
    </>

  )
}

export default ThankYouRegister
