import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import getCountry from "../apis/apiCountry";
const AuthCountry = () => {
  let navigate = useNavigate();
  useEffect(() => {
    getCountry().then((country) => {
      if (country === "Bolivia") {
        navigate("/bo");
      } else if (country === "Colombia") {
        navigate("/co");
      } else {
        navigate("/mx");
      }
    });
  }, []);
  
};

export default AuthCountry;
