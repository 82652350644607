import React from 'react'
import '../assets/css/components/credito.css'
import nonSegmentBank from '../assets/images/Nonbanked_segment.png'
import Button from './Button';
const Credito = ({ countryName, modalCountry }) => {
    const validacionCountry = countryName === 'bo' ? 'Bolivia' : countryName === 'mx' ? 'México' : countryName === 'co' ? 'Colombia' : ""


    return (
        <div className="credito">
            <div className="credito__time">
                <div className="credito__time--phone">
                    <img src={nonSegmentBank} alt="" />
                </div>
                <div className="credito__texto">
                    <p className="credito__subline">CRÉDITO</p>
                    <h2>Haz realidad tus sueños</h2>
                    <p>El acceso a crédito y otros servicios financieros debería ser universal e inclusivo, independiente de si cuentas con historial financiero. Ya sea que quieras iniciar un negocio, crecerlo, financiar la educación de tus hijos, atender tu salud y más. Todo esto de acuerdo a tu perfil y a tasas justas.</p>
                    <div className="credito__button">
                            <Button clases={'left'} click={modalCountry} />
                      
                        <p>- ¡ Es Gratis !</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Credito