import "../assets/css/landingaura.css";
import FirstSection from "../components/landingsocial/FirstSection";
import FooterLanding from "../components/landingsocial/FooterLanding";
import FourdSection from "../components/landingsocial/FourdSection";
import HeaderMyPyme from "../components/landingsocial/Header";
import OneSection from "../components/landingsocial/OneSection";
import SecondSection from "../components/landingsocial/SecondSection";
import SelectCountryModal from "../components/SelectCountryModal";
import TwoSection from "../components/landingsocial/TwoSection";
import mockupAppAura from "../../src/assets/images/mockup_banner-mobile.png";
import { usePageAnalyitics } from "../helpers/hooks/usePageAnalytics";
import { useState } from "react";

import porQueAuraPng from "../../src/assets/images/A.porqueAURA.png";
import kycPng from "../../src/assets/images/01.kyc.png";
import scorePng from "../../src/assets/images/02.score.png";
import tipsPng from "../../src/assets/images/03.tips.png";
import serFinPng from "../../src/assets/images/04.Serv_fin.png";
import tomaDecisiones from "../../src/assets/images/05.Toma_de_dec.png";
import { Helmet } from "react-helmet";
const LandingAura = () => {
  usePageAnalyitics();
  const [modalCountry, setModalCountry] = useState(false);

  return (
    <>
      <Helmet>
        <title>Aura Information | AURA Financial</title>
      </Helmet>
      {modalCountry && <SelectCountryModal modalCountry={setModalCountry} />}
      <div className="aurasocials">
        <HeaderMyPyme />
        <main className="landing">
          <FirstSection
            openModal={setModalCountry}
            title={
              "La aplicación de finanzas personales hecha para ti y tu negocio miPYME"
            }
            text={
              "Aprende como controlar tus finanzas personales ahorrar, invertir e incluso como obtener un crédito para tu negocio."
            }
            textButton={"Registra tu negocio "}
            imageUrl={mockupAppAura}
          />
          <SecondSection
            title={`Las oportunidades 
                        para tu negocio deben ser 
                        transparentes y democráticas`}
            text={`Estamos convencidos de que todos deben dar forma a su
                        futuro financiero. Estaremos a tu lado en todo el camino
                        de la vida financiera de tu negocio.`}
            imageUrl={porQueAuraPng}
          />
          <main className="landing__sections">
            <h2 className="landing__sections--title">¿Como funciona?</h2>
            <OneSection
              number={"01"}
              title={"Cuéntanos de ti"}
              text={
                "Con tu información y la de tu negocio ayudaremos a armar tu expediente financiero."
              }
              buttonText={"Regístrate"}
              imageUrl={kycPng}
              openModal={setModalCountry}
            />
            <TwoSection
              number={"02"}
              title={"Conoce tu perfil crediticio"}
              text={
                "Descubre tu reporte de InfoCred, aprende como se interpreta, como puedes mejorarlo y sacar lo mejor de él."
              }
              buttonText={"Quiero AURA"}
              imageUrl={scorePng}
              openModal={setModalCountry}
            />

            <OneSection
              title={"Recibe tips de educación financiera"}
              text={
                "De manera periódica recibirás mensajes cortos para tener bajo control tus finanzas personales y las de tu negocio."
              }
              number={"03"}
              buttonText={"Regístrate"}
              imageUrl={tipsPng}
              openModal={setModalCountry}
            />

            <TwoSection
              title={"Conoce los servicios financieros"}
              text={
                "En la palma de la mano, recibe recomendaciones claras sobre servicios de crédito disponibles en el mercado, sus tasas de interés y productos de inversión, que hagan sentido con tus requerimientos y perfil."
              }
              number={"04"}
              buttonText={"Regístrate"}
              imageUrl={serFinPng}
              openModal={setModalCountry}
            />
            <OneSection
              title={"Toma decisiones que ayuden a crecer tu negocio"}
              text={
                "Con tu perfil financiero, el conocimiento de como manejar mejor tus finanzas y con los servicios financieros que se ajustan a tus necesidades, podrás tomar la mejor decisión de como hacer crecer tu negocio. "
              }
              number="05"
              buttonText={"Regístrate"}
              imageUrl={tomaDecisiones}
              openModal={setModalCountry}
            />
          </main>

          <FourdSection openModal={setModalCountry} />
          <FooterLanding />
        </main>
      </div>
    </>
  );
};

export default LandingAura;
