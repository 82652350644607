import React from 'react'
import PropTypes from 'prop-types'

import Button from './Button';

import creditIcon from '../assets/images/creditcard.svg'
import securityHealtIcon from '../assets/images/securityheart 1 (1).svg'
import personIcon from '../assets/images/person.svg'
import carIcon from '../assets/images/car.svg'
import homeIcon from '../assets/images/home 1.svg'
import cashIcon from '../assets/images/cash 1.svg'
import cellPhoneImage from '../assets/images/mockupAppAura.png'
import banksMex from '../assets/images/banksmex-removebg.png'

import '../assets/css/components/servicios.css'


const Servicios = ({countryName , modalCountry}) => {
    const validacionCountry = countryName === 'bo' ? 'Bolivia' : countryName === 'mx' ?  'México' : countryName === 'co' ? 'Colombia' :""
  
    return (
        <div className="servicios">
            <div className=" wow fadeInDown animated">
                <h3 className="title--estrategies" id='title--bottom'>Accede a los mejores productos financieros de {validacionCountry}</h3>
                <p>Confiables y adecuados según tu perfil.</p>
            </div>
            <div className="servicios__container">
                <div className="servicios__list">
                    <div className="servicios__list--item">
                        <img src={cashIcon} alt='moneda'/>
                        <p>Inversiones</p>
                    </div>
                    <div className="servicios__list--item">
                        <img src={personIcon} alt='persona'  />
                        <p>Préstamos personales</p>
                    </div>

                    <div className="servicios__list--item">
                        <img src={securityHealtIcon}  alt='seguro de vida'  />
                        <p>Seguros de vida y gastos médicos</p>
                    </div>
                    <div className="servicios__list--item">
                        <img src={carIcon} alt='carro' />
                        <p>Créditos y seguros de auto</p>
                    </div>

                    <div className="servicios__list--item">
                        <img src={homeIcon} alt='hipotecas casas' />
                        <p>Hipotecas</p>
                    </div>

                    <div className="servicios__list--item">
                        <img src={creditIcon} alt='creditos'/>
                        <p>Tarjetas de crédito</p>
                    </div>
                    
                            <Button clases={"title__button--si"} contenido='¡Quiero Aura!' click={()=>{modalCountry(true)}}></Button>
                       
                </div>
                <div className="servicios__image">
                    <img src={countryName === 'bo' ? cellPhoneImage : banksMex} alt='imagen de telefono con bancos app aura' />
                </div>
            </div>
        </div>
    )
}
Servicios.propTypes = {
    countryName: PropTypes.string,
}
Servicios.defaultProps = {
    countryName: "",
}


export default Servicios