import { BrowserRouter, Route, Routes } from 'react-router-dom'

import App from './App';
import Appholistica from './pages/Appholistica';
import AuthCountry from './components/AuthCountry';
import ConfirmationEmail from './pages/ConfirmationEmail';
import MypymeRoutes from './MypymeRoutes';
import NotFound from './pages/NotFound';
import Privacity from './pages/Privacity';
import React from 'react'
import ReactGA from 'react-ga4';
import RegisterQR from './pages/RegisterQR';
import Servicios from './components/Servicios';
import ThanYou from './pages/ThanYou';
import ThankYouRegister from './pages/ThankYouRegister';

import LandingAura from './pages/LandingAura'
ReactGA.initialize('G-NJW62F94ZT');

const Routing = () => {

    return (
        <BrowserRouter>
            <Routes>
               
                <Route index exact path={`/`} element={<AuthCountry />} />
                <Route exact path={`/mx`} element={<App countryName={'mx'} />} />
                <Route exact path={`/bo`} element={<App countryName={'bo'} />} />
                <Route exact path={`/co`} element={<App countryName={'co'} />} />


                <Route exact path={'/mx/howto'} element={<Appholistica countryName={'mx'} />} />
                <Route exact path={'/bo/howto'} element={<Appholistica countryName={'bo'} />} />
                <Route exact path={'/co/howto'} element={<Appholistica countryName={'co'} />} />

                <Route exact path={'/thankyou'} element={<ThanYou />} />
                <Route exact path={'/bo/thankyou-register'} element={<ThankYouRegister />} />


                <Route exact path={'/confirmation'} element={<ConfirmationEmail />} />

                <Route exact path={'/pruebas'} element={<Servicios />} />

                <Route exact path={'/bo/registro-BO'} element={<RegisterQR />} />

                <Route exact path={'/privacity'} element={<Privacity />} />

                <Route exact path={'/bo/mipyme'} element={<LandingAura />} />
                

                <Route path="*" element={<NotFound />} />
            </Routes>
        </BrowserRouter>
    )


}

export default Routing
// padding imagenes
// netlify borrar