import React from 'react'
import EmailsLanding from '../components/EmailsLanding'
import { Helmet } from "react-helmet";
import { usePageAnalyitics } from '../helpers/hooks/usePageAnalytics';

const ThanYou = () => {

  usePageAnalyitics()
  
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Thank You | AURA Financial</title>
      </Helmet>
      <EmailsLanding
        title='¡Gracias por suscribirte!'
        text='Confirma a través del enlace que enviamos a tu correo electrónico.
        Si no encuentras el correo, revisa tu carpeta de SPAM.'
        timeReturnHome={15000}
      />
    </>

  )
}

export default ThanYou


// links de header estan funcionando raro
